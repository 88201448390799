import React, { useState } from "react"
import "./smíðagutlara.css";
import "../../../almenncss/grunncss.css";
import miðlarabón from "../../../miðlarabón.js"
import ÚtfillingarTexti from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngartexti/ÚtfillíngarTexti.js";
import ÚtfillíngarTala from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngartala/ÚtfillíngarTala.js";
import ÚtfillingarMöguleikar from "../../../hlutir/almennirhlutar/útfillíngar/útfillíngarmöguleikar/ÚtfillíngarMöguleikar.js";
import TakkiAðgerð1 from "../../../hlutir/almennirhlutar/takkar/takkiAðgerð1/TakkiAðgerð1";
import mindþjöppun from 'browser-image-compression';
import VilluHleðurTakki from "../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import VilluHleðurTakki2 from "../../../hlutir/almennirhlutar/villaoghleður/villuhleðurtakki/VilluHleðurTakki2";
import { fáMiðlaravillu } from "../../../gjörníngar/ímsir.js";
import "../../árnaðarmaður/umárnaðarmann/umárnaðarmann.css";
import "../../../almenncss/upplisíngasíður.css"
import Smeija from "../../../hlutir/almennirhlutar/smeija/Smeija.js";
import Takki1 from "../../../hlutir/almennirhlutar/takkar/takki1/Takki1.js";

// const texti = [`
// Eftirnafnið er til,
// ásamt skírnarnafni,
// að létta leit annarra.
// Gælunafnið er
// svo góðvinir þig þekkji
// í listum leikja og liða.
// `,
// `
// Til að þú þekkist
// þörf er að hafa,
// gutlaranafnið geimt.
// Ei þú vilt
// að annar reini
// þikjast vera þú.
// `,
// `
// Netfángið stoppar
// stanslausa smíði
// gutlara eins guma.
// Einnig er gott,
// geta endurheimt
// likilorð gleimt og glatað.
// `,
// `
// Hefur þú í huga 
// að hanna þann leik
// með lista af þínum líkum.
// Heppilegt ég tel
// hafa kin og aldur
// takmarkast við téða.
// `,
// `
// Mennskari þig gerir
// Mindin þín sæt
// Mundað brosa breitt.
// `
// ]


const SmíðaGutlara = ({likill}) => {
  //gögn gutlara
  const [nafn, skikkaNafni] = useState("");
  const [gælunafn, skikkaGælunafni] = useState("");
  const [eftirnafn, skikkaEftirnafni] = useState("");
  const [gutlaranafn, skikkaGutlaranafni] = useState("");
  const [netfáng, skikkaNetfángi] = useState("");
  const [likilorð, skikkaLikilorði] = useState("");
  const [endurtekiðLikilorð, skikkaEndurteknuLikilorði] = useState("");
  const [fæðíngarár, skikkaFæðíngarári] = useState(1969);
  const [veljaKin, skikkaVeljaKin] = useState(false);
  const [kin, skikkaKini] = useState("");
  const [mind, skikkaMind] = useState(null);
  const [veljaFæðíngarár, skikkaVeljaFæðíngarár] = useState(false);
  const [stuttLikilorð, skikkaStuttLikilorð] = useState(false);

  //frá miðlara
  const [svarMiðlara, skikkaSvariMiðlara] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  //endursenda
  const [svarMiðlaraEndursenda, skikkaSvariMiðlaraEndursenda] = useState(null);
  const [hleðurEndursenda, skikkaHleðurEndursenda] = useState(null);
  const [villaEndursenda, skikkaVilluEndursenda] = useState(null);
  
  //þannig ef þarf að endursenda tölvuskeiti þá eru upplísíngar geimdar
  const [sentNetfáng, skikkaSentuNetfángi] = useState("");
  const [sentLikilorð, skikkaSentuLikilorði] = useState("");

  const [spurn, skikkaSpurn] = useState(null);
  const [spurnKin, skikkaSpurnKin] = useState(false);

  //const [afkverjuÞetta, skikkaAfkverjuÞetta] = useState(false);


  async function endursendaPóst() {
    skikkaHleðurEndursenda("Endursendir...");
    skikkaVilluEndursenda(null);
    skikkaSvariMiðlaraEndursenda(null);
    try {
      const svarMiðlara = await miðlarabón.post("/gutlaraskraning/endursendapostfirirgutlarasmid", {
        netfáng: sentNetfáng,
        likilorð: sentLikilorð,
        likill
      })
      
      if (svarMiðlara.status === 200) {
        skikkaSvariMiðlaraEndursenda(svarMiðlara.data)
      }
    } catch (villaBónar) {
      skikkaVilluEndursenda(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurEndursenda(null);
  }

  async function smíðaGutlara(viðburður) {
    if (viðburður) {
      viðburður.preventDefault();
    }
    skikkaVillu(null);
    skikkaHleður("Smíðar...");
    skikkaSvariMiðlara(null);

    if (!likilorð) {
      skikkaVillu("Það væri skárra að hafa lykilorð");
      skikkaHleður(null);
      return;
    }
    if (likilorð !== endurtekiðLikilorð) {
      skikkaVillu("Lykilorð er ekki það sama og endurtekið lykilorð");
      skikkaHleður(null);
      return;
    }
    if (likilorð.length < 10 && !stuttLikilorð) {
      skikkaVillu('Mæli með að hafa lengra lykilorð, en þú ræður (ýttu aftur á "Smíða gutlara!" til að halda núverandi lykilorði)');
      skikkaHleður(null);
      skikkaStuttLikilorð(true);
      return;
    }

    const saxaðNafn = nafn.trim();
    const saxaðGælunafn = gælunafn.trim();
    const saxaðNetfáng = netfáng.trim();
    const saxaðEftirnafn = eftirnafn.trim();
    const saxaðGutlaranafn = gutlaranafn.trim();

    if (saxaðNafn.length < 2) {
      skikkaVillu("Þarft að hafa skírnarnafn, en gælunafn og eftirnafn eru ekki nauðsynleg");
      skikkaHleður(null);
      return;
    }
    if (!saxaðNetfáng) {
    }
    if (saxaðNetfáng.search("@") < 0) {
      skikkaVillu("Mér sýnist netfang þitt ekki vera netfang");
      skikkaHleður(null);
      return;
    }
    if (!saxaðGutlaranafn) {
      skikkaVillu("Gutlaranafnið er mikilvægt, það einkennir þig");
      skikkaHleður(null);
      return;
    }
    if (saxaðGutlaranafn.search("@") > -1) {
      skikkaVillu("Æskilegt væri að hafa ekkert „@“ í gutlaranafninu");
      skikkaHleður(null);
      return; 
    } 
    const lágstafanetfáng = saxaðNetfáng.toLowerCase();
    let sentFæðíngarár = null;
    if (veljaFæðíngarár) {
      sentFæðíngarár = fæðíngarár;
    }
    let sentKin = null;
    if (veljaKin) {
      sentKin = kin;
    }
    const notaðlikilorð = likilorð;
    
    try {
      let svarmiðlara = null;
      if (mind) {
        const stillíngarÞjöppunar = {
          maxSizeMB: 0.05,//0.1,
          maxWidthOrHeight: 512,//760,//1920,
          useWebWorker: true
        }
        let þjöppuðMind;
        try {
          þjöppuðMind = await mindþjöppun(mind, stillíngarÞjöppunar);
        } catch (villa) {
          skikkaVillu("Tókst ekki að þjappa mynd, prófaðu aftur, annars skaltu sjá til að myndin er jpg eða png skjal");
          return;
        }
        const útfillíngargögn = new FormData();
        útfillíngargögn.append("nafn", saxaðNafn);
        útfillíngargögn.append("gaelunafn", saxaðGælunafn);
        útfillíngargögn.append("netfang", lágstafanetfáng);
        útfillíngargögn.append("eftirnafn", saxaðEftirnafn);
        útfillíngargögn.append("gutlaranafn", saxaðGutlaranafn);
        útfillíngargögn.append("likilord", likilorð);
        útfillíngargögn.append("faedingarar", sentFæðíngarár);
        útfillíngargögn.append("kin", sentKin);
        útfillíngargögn.append("likill", likill);
        útfillíngargögn.append("mind", þjöppuðMind, þjöppuðMind.name);
        svarmiðlara = await miðlarabón.post("/gutlaraskraning/buatilgutlaramedmind", útfillíngargögn);
      } else {
        const útfillíngargögn = {
          nafn,
          gaelunafn: gælunafn,
          netfang: lágstafanetfáng,
          eftirnafn,
          gutlaranafn,
          likilord: likilorð,
          faedingarar: sentFæðíngarár,
          kin: sentKin,
          likill
        }
        svarmiðlara = await miðlarabón.post("/gutlaraskraning/buatilgutlara", útfillíngargögn);
      }
      if (svarmiðlara.status === 201) {
        skikkaSvariMiðlara(svarmiðlara.data);
        skikkaSentuNetfángi(lágstafanetfáng);
        skikkaSentuLikilorði(notaðlikilorð);
      }
    } catch (villaBónar) {
      skikkaVillu(fáMiðlaravillu(villaBónar));
    }
    skikkaHleður(null);
  }

 
  return (
    <section className="smíðagutlara-aðal umgjörð-lóðrétt-ómiðjað">
      {likill ? (
        <>
          <p className="texti">
            Þegar þú býrð til aðgang hér þá verður þessari gátt lokað og aðgangur (gutlari) þinn verður skráður í leikinn (með sömu stöðu og hér).
          </p>
        </>
      ) : (
        <>
          <h2 className="smíðagutlara-firirsögn">Smíða Gutlara</h2>
          {/* <div className="smeija-takki">
            <Takki1 texti="Sjá kynningu á vefsíðu" til="/kinninggutlari"/>
          </div> */}
          <p className="texti">
            Maður er manns gaman, og leikir líka.
          </p>
          <p className="texti">
            Áður en þú smíðar gutlara er vert að útskýra. Tilgangur vefsíðunar er að auðvelda það að koma fólki saman að leika íþróttir. Aðgangur/notandi á síðunni er kallaður gutlari. Eftir að þú skráir þig inn er grár takki sem stendur á "Um", þar geturu lesið meira um vefsíðuna.
          </p>
          <p className="texti">
            Vefsíðan er ný, þannig gott er að hafa smá þolinmæði gagnvart villum og slíku.
          </p>
          <p className="texti">
            Á forsíðunni eru listar af leikjum sem þú ert í, þeir listar eru tómir fyrst. Til að byrja með er best að bæta við vinum. Vinir á síðunni eru kallaðir leikjanautar. Farðu inn á leikjanautasíðu (grár takki sem stendur á "Leikjanautar") og finndu leikjanauta. Svo þegar þú hefur eignast nokkra leikjanauta geturu farið að búa til leiki og lið. Fínt er líka að heimsækja orðaforðasíðuna.
          </p>
        </>
      )}
      {/* {(!afkverjuÞetta) && (
        <div className="smíðagutlara-tilkvers">
          <TakkiAðgerð1 texti="Ég vil vita afkverju þú þarf þessar upplísíngar til að smíða gutlara. Yrtu á mig svari þínu í ljóðahætti" aðgerð={() => skikkaAfkverjuÞetta(true)}/>
        </div>
      )}
      {afkverjuÞetta && (
        <>
          <h4 className="smíðagutlara-tilkvers">Afhverju þarf þessar upplísíngar</h4>
          <pre className="smíðagutlara-tilkvers-texti">
             {texti[0]}
          </pre>
          <pre className="smíðagutlara-tilkvers-texti">
             {texti[1]}
          </pre>
          <pre className="smíðagutlara-tilkvers-texti">
             {texti[2]}
          </pre>
          <pre className="smíðagutlara-tilkvers-texti">
             {texti[3]}
          </pre>
          <pre className="smíðagutlara-tilkvers-texti">
             {texti[4]}
          </pre>
        </>
      )} */}
      <form className="smíðagutlara-inntök">
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Skírnarnafn" 
            gildi={nafn} 
            breitaGildi={skikkaNafni}
            birtaSmeiju={() => skikkaSpurn("Skírnarnafn og eftirnafn er svo auðveldara er fyrir aðra að finna þig. Það er ekki nauðsynlegt að gefa upp eftirnafn né gælunafn.")}
          />
        </div>
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Eftirnafn" 
            gildi={eftirnafn} 
            breitaGildi={skikkaEftirnafni}
          />
        </div>
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Gælunafn" 
            gildi={gælunafn} 
            breitaGildi={skikkaGælunafni}
            birtaSmeiju={() => skikkaSpurn("Gælunafn er nafnið sem birtist í listum, t.d. í lista yfir leikmenn leiks eða liðsmenn liðs")}
          />
        </div>
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Gutlaranafn" 
            gildi={gutlaranafn} 
            breitaGildi={skikkaGutlaranafni}
            birtaSmeiju={() => skikkaSpurn("Tveir gutlarar geta ekki haft sama gutlaranafn. Tilgangur þess er aðallega svo hægt er að þekkja í sundur tvo gutlara sem hafa sama skírnarnafn, eftirnafn og gælunafn.")}
          />
        </div>
        <div className={`smíðagutlara-inntak-lesmál ${!veljaFæðíngarár && "smíðagutlara-netfáng"}`}>
          <ÚtfillingarTexti 
            texti="Netfang" 
            gildi={netfáng} 
            breitaGildi={skikkaNetfángi}
            tegund="email"
            birtaSmeiju={() => skikkaSpurn("Skrá þarf netfang svo hægt er að endurheimta gutlara ef maður gleymir lykilorðinu sínu")}
          />
        </div>
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Lykilorð" 
            gildi={likilorð} 
            breitaGildi={skikkaLikilorði}
            tegund="password"
          />
        </div>
        <div className="smíðagutlara-inntak-lesmál">
          <ÚtfillingarTexti 
            texti="Endurtekið lykilorð" 
            gildi={endurtekiðLikilorð} 
            breitaGildi={skikkaEndurteknuLikilorði}
            tegund="password"
          />
        </div>
        {veljaFæðíngarár ? (
          <div className="smíðagutlara-inntak-lesmál smíðagutlara-fæðíngarár">
            <ÚtfillíngarTala texti="Fæðingarár" gildi={fæðíngarár} breitaGildi={skikkaFæðíngarári}/>
            <TakkiAðgerð1 texti="Hætta við fæðingarár" aðgerð={() => skikkaVeljaFæðíngarár(false)}/>
          </div>
        ) : (
          <div className="smíðagutlara-veljafæðíngarártakki">
            <TakkiAðgerð1 texti="Velja fæðingarár" aðgerð={() => skikkaVeljaFæðíngarár(true)}/>
          </div>
        )}
        {veljaKin ? (
          <div className="smíðagutlara-inntak-lesmál smíðagutlara-veljakin">
            <ÚtfillingarMöguleikar 
              texti="Velja kyn" 
              gildi={kin} 
              breitaGildi={skikkaKini} 
              möguleikar={["Dama \u2640", "Herra \u2642"]}
              birtaSmeiju={skikkaSpurnKin}
            />
            <div className="smíðagutlara-veljafæðíngarártakki">
              <TakkiAðgerð1 texti="Hætta við kynjaval" aðgerð={() => skikkaVeljaKin(false)}/>
            </div>
          </div>
        ) : (
          <div className="smíðagutlara-veljafæðíngarártakki">
            <TakkiAðgerð1 texti="Velja kyn" aðgerð={() => skikkaVeljaKin(true)}/>
          </div>
        )}
        {!mind && (
          <>
            <label className="umgjörð-lóðrétt smellanlegur smíðagutlara-innsendamind" htmlFor="mind">Velja mynd fyrir gutlara</label>
            <input 
            type="file"
            id="mind"
            name="mind"
            className="smíðagutlara-inntakmind"
            onChange={(v) => skikkaMind(v.target.files[0])}
            accept="image/png, image/jpeg"
            />
          </>
        )}
        {mind && <img src={URL.createObjectURL(mind)} className="smíðagutlara-mind" onClick={() => skikkaMind(null)} alt="falleg mynd"/>}
        <div className="umgjörð-lóðrétt smíðagutlara-staðfestaval">
          {svarMiðlara && (
            <div className="smíðagutlara-smíðagutlara-takki-minni">
              <VilluHleðurTakki2
                svarMiðlara={svarMiðlaraEndursenda}
                villa={villaEndursenda}
                hleður={hleðurEndursenda}
                aðgerð={endursendaPóst}
                textiAðgerðar="Endursenda póst"
              />
            </div>
          )}
          <div className="smíðagutlara-smíðagutlara-takki">
            <VilluHleðurTakki
              svarMiðlara={svarMiðlara}
              villa={villa}
              hleður={hleður}
              aðgerð={smíðaGutlara}
              textiAðgerðar="Smíða gutlara!"
            />
          </div>
        </div>
      </form>
      {spurn && (
        <Smeija lokaSmeiju={(gildi) => {if (!gildi) {skikkaSpurn(null)}}}>
          <p className="smeija-aðaltakki spurn-rammi">{spurn}</p>
        </Smeija>
      )}
      {spurnKin && (
        <Smeija lokaSmeiju={(gildi) => {if (!gildi) {skikkaSpurnKin(null)}}}>
          <p className="smeija-aðaltakki spurn-rammi">Aldur og kyn er svo hægt er að memma (verða hluti af) leik sem einskorðast við aldur eða kyn. Það getur verið sérlega hentugt í opnum leikjum. (Það er ekki nauðsynlegt að skrá aldur né kyn).</p>
        </Smeija>
      )}
    </section>
  )
}

export default SmíðaGutlara;