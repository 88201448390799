import "../../../../almenncss/grunncss.css";
import "../../listi/leppurtímaskrá/smeijur/leikurtímaskrá/leikurtímaskrá.css";
import "../../../../almenncss/villaoghleður.css";
import { useContext, useEffect, useRef, useState } from "react";
import { fáDagLesmál, fáKlstMínLesmál, fáDagLesmálÞágu, vikudagarÞáguFleir } from "../../../../gjörníngar/fáTímaLesmál";
import {ÁrnaðarmaðurSamheingi} from "../../../../samheingi/árnaðarmaðurSamheingi"
import TakkiAðgerð1 from "../../takkar/takkiAðgerð1/TakkiAðgerð1";
import miðlarabón from "../../../../miðlarabón";
import { fáMiðlaravillu } from "../../../../gjörníngar/ímsir";
import TímaskrárFastur from "../../tímaskrárfastur/TímaskrárFastur";
import Smeija from "../../smeija/Smeija";
import TakkiAðgerð2 from "../../takkar/takkiAðgerð2/TakkiAðgerð2";
import VilluHleðurTakki from "../../villaoghleður/villuhleðurtakki/VilluHleðurTakki";
import Gutlarasmeija from "../../gutlarasmeija/Gutlarasmeija";
import Liðasmeija from "../../liðasmeija/Liðasmeija";
import { fáUpptekni } from "../../listi/leppurtímaskrá/LeppurTímaskrá";
import LeikurTímaskráTakkar from "../../listi/leppurtímaskrá/smeijur/leikurtímaskrá/leikurtímaskrátakkar/LeikurTímaskráTakkar";
import LeikirFastur from "./leikirfastur/LeikirFastur";
import SpjallÁrnaðarmaður from "../../spjallárnaðarmaður/SpjallÁrnaðarmaður";
import VeljaLiðFastur from "../../veljaliðfastur/VeljaLiðFastur";
import TímaskráGreiðsla from "../../listi/leppurtímaskrá/smeijur/leikurtímaskrá/tímaskrágreiðsla/TímaskráGreiðsla";

const ÓtækurFasturSmeija = ({fasturTími, skikkaFöstumTíma, einkiFastsTíma}) => {
  const [nafnVallar, skikkaNafniVallar] = useState(null);
  const [hleður, skikkaHleður] = useState(null);
  const [villa, skikkaVillu] = useState(null);

  const [sjáTímaskrár, skikkaSjáTímaskrár] = useState(false);
  const [sjáLeiki, skikkaSjáLeiki] = useState(false);

  const [sjáLeikstefni, skikkaSjáLeikstefni] = useState(false);
  const [sjáLið, skikkaSjáLið] = useState(false);

  const [svarTakki, skikkaSvarTakki] = useState(null);
  const [hleðurTakki, skikkaHleðurTakki] = useState(null);
  const [villaTakki, skikkaVillaTakki] = useState(null);

  const tímiFargaður = useRef(fasturTími ? fasturTími.fargaður : false);
  const [feinginnFastur, skikkaFeingnumFöstum] = useState(fasturTími);
  const [reinaðEiða, skikkaReinaðEiða] = useState(false);

  const [veljaLið, skikkaVeljaLið] = useState(false);
  const [breittíFirirætlun, skikkaBreittíFirirætlun] = useState(false);

  const [breittUpptekni, skikkaBreittriUpptekni] = useState(fasturTími ? fasturTími.upptekni : null);
  const breittNíSkilaboð = useRef(fasturTími ? fasturTími.ní_skilaboð : false);
  const tilAðBreitaUpptekni = useRef();
  const greiðslaStaðfest = useRef(fasturTími ? fasturTími.staðfest_greiðsla : null);
  const sótt = useRef(false);

  tilAðBreitaUpptekni.current = breittUpptekni;

  const {völlur} = useContext(ÁrnaðarmaðurSamheingi);

  const einki = feinginnFastur ? (feinginnFastur.aðaltími ? feinginnFastur.aðaltími : feinginnFastur.einki) : null;
  
  async function eiðaFöstum() {
    skikkaHleðurTakki("Eyðir föstum tíma");
    try {
      const svarMiðlara = await miðlarabón.delete(`fastur/eidafostum/${einki}`);
      tímiFargaður.current = true;
      skikkaSvarTakki(svarMiðlara.data);
    } catch (villaBónar) {
      skikkaVillaTakki(fáMiðlaravillu(villaBónar));
    }
    skikkaHleðurTakki(null);
  }



  useEffect(() => {

    async function náíGögnTímaskráar() {
      skikkaHleður("Sækir völl tíma...");
      try {
        const náíGögn = await miðlarabón.get(`fastur/favollfastur/${einki}`);
        skikkaNafniVallar(náíGögn.data);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    async function náíTímaskrá() {
      skikkaHleður("Sækir fastan tíma...");
      try {
        const náíGögn = await miðlarabón.get(`fastur/fasturtimi/${einkiFastsTíma}`);
        const {nafn, aukanafn, stærð_undirvallar, upptekni} = náíGögn.data;
        skikkaFeingnumFöstum(náíGögn.data);
        skikkaNafniVallar({nafn, aukanafn, stærð_undirvallar});
        skikkaBreittriUpptekni(upptekni);
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }

    async function náíFirirætlun() {
      skikkaHleður("Sækir fastan tíma...");
      try {
        const náíGögn = await miðlarabón.get(`fastur/firiraetlun/${einki}`);
        const {nafn, aukanafn, stærð_undirvallar, ní_skilaboð, staðfest_greiðsla} = náíGögn.data;
        breittNíSkilaboð.current = ní_skilaboð;
        greiðslaStaðfest.current = staðfest_greiðsla;
        sótt.current = true;
        skikkaFeingnumFöstum(náíGögn.data);
        skikkaNafniVallar({nafn, aukanafn, stærð_undirvallar});
      } catch (villaBónar) {
        skikkaVillu(fáMiðlaravillu(villaBónar));
      }
      skikkaHleður(null);
    }
    if (breittíFirirætlun) {
      //ná í gögn nírrar firirætlunar
      náíFirirætlun();
    } else if (einkiFastsTíma && !feinginnFastur) {
      //ef eingin gögn eru til staðar
      náíTímaskrá();
    } else if (feinginnFastur && feinginnFastur.er_firirætlun && !feinginnFastur.fargaður) {
      //ef þarf líka að ná í gögn firirætlunar
      náíFirirætlun();
    } else if (!nafnVallar && feinginnFastur && feinginnFastur.aðaltími && !feinginnFastur.fargaður) {
      náíGögnTímaskráar();
    }

    //uppfæra á leppi ef breitt var einkverju
    return () => {
      if (tímiFargaður.current || (feinginnFastur && (tilAðBreitaUpptekni.current !== feinginnFastur.upptekni || breittNíSkilaboð.current !== feinginnFastur.ní_skilaboð || greiðslaStaðfest.current !== feinginnFastur.staðfest_greiðsla))) {
        if (skikkaFöstumTíma) {
          skikkaFöstumTíma(firriFastur => {
            const uppfærðurFastur = {...firriFastur};
            uppfærðurFastur.upptekni = tilAðBreitaUpptekni.current;
            uppfærðurFastur.ní_skilaboð = breittNíSkilaboð.current;
            uppfærðurFastur.fargaður = tímiFargaður.current;
            uppfærðurFastur.staðfest_greiðsla = greiðslaStaðfest.current;
            return uppfærðurFastur;
          })
        }
      }
    }

  }, [einki, einkiFastsTíma, breittíFirirætlun])

  return (
    <section className="grunn-smeija">
      {nafnVallar ? (
        <h3 className="smeija-firirsögn">{nafnVallar.nafn} {nafnVallar.aukanafn} {nafnVallar.stærð_undirvallar}</h3>
      ) : (
        <h3 className="smeija-firirsögn">{völlur.nafn} {völlur.aukanafn} {völlur.stærð_undirvallar}</h3>  
      )}
      {hleður && <p className="leikurtímaskrá-firirsögn villaoghleður-hleður">{hleður}</p>}
      {villa && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">{villa}</p>}
      {tímiFargaður.current && <p className="leikurtímaskrá-firirsögn villaoghleður-villa">Föstum tíma hefur verið fargað</p>}

      {feinginnFastur && (
        <>
          {(feinginnFastur.tegund_tímabils == 1) && (
            <>
              <p className="leikurtímaskrá-tími">Vikulega</p>
              <p className="leikurtímaskrá-tími">{vikudagarÞáguFleir[feinginnFastur.dagur - 1]}</p>
            </>
          )}
          {(feinginnFastur.tegund_tímabils == 2) && (
            <>
              <p className="leikurtímaskrá-tími">Mánaðarlega</p>
              <p className="leikurtímaskrá-tími">{feinginnFastur.dagur}. hvers mánaðar</p>
            </>
          )}
          <p className="leikurtímaskrá-tími">{fáKlstMínLesmál(feinginnFastur.klst_frá, feinginnFastur.mín_frá, feinginnFastur.klst_til, feinginnFastur.mín_til)}</p>
          <p className="leikurtímaskrá-gögn">{fáDagLesmál(new Date(feinginnFastur.tímabil_frá))}</p>
          <p className="leikurtímaskrá-tími">Til og með</p>
          <p className="leikurtímaskrá-gögn">{fáDagLesmálÞágu(new Date(feinginnFastur.tímabil_til))}</p>
          <p className="leikurtímaskrá-tími">{fáUpptekni(breittUpptekni)}</p>
          {feinginnFastur.fargaður && <p className="leikurtímaskrá-tími">FARGAÐ</p>}
          {feinginnFastur.nafngutlara && <p className="leikurtímaskrá-gögn" onClick={() => skikkaSjáLeikstefni(true)}>Ætlunarstefnir: <span className="leikurtímaskrá-leikstefnir">{feinginnFastur.nafngutlara}</span></p>}
          {feinginnFastur.liðsnafn && <p className="leikurtímaskrá-gögn" onClick={() => skikkaSjáLið(true)}>Lið: <span className="leikurtímaskrá-leikstefnir">{feinginnFastur.liðsnafn}</span></p>}
          {(feinginnFastur.greiðsla && sótt.current) && (
            <TímaskráGreiðsla 
              gögnTímaskráar={feinginnFastur} 
              greiðslaStaðfest={greiðslaStaðfest}
              einki={feinginnFastur.einki}
              fasturTími={true}
            />
          )}
          {(feinginnFastur.upptekni !== 4) && (
            <LeikurTímaskráTakkar
              upptekni={breittUpptekni} 
              einki={einki} 
              skikkaBreittriUpptekni={skikkaBreittriUpptekni}
              tímaskráFargað={tímiFargaður}
              einkavöllur={völlur.einkavöllur}
              fastur={true}
            />
          )}
          {sjáTímaskrár ? (
            <TímaskrárFastur fasturTími={feinginnFastur}/>
          ) : (
            <div className="leikurtímaskrá-gögn">
              <TakkiAðgerð1 texti={(feinginnFastur.upptekni === 4) ? "Sjá tímaskrár hjá föstum tíma" : "Sjá ætlunartímaskrár"} aðgerð={() => skikkaSjáTímaskrár(true)}/>
            </div>
          )}
          {(feinginnFastur.upptekni == 4) ? (
            <>
              <div className="smeija-takki">
                <TakkiAðgerð1 
                  texti="Eyða föstum tíma"
                  aðgerð={() => skikkaReinaðEiða(true)}
                />
              </div>
              {(feinginnFastur.upptekni == 4 && !feinginnFastur.aðaltími) && (
                <div className="smeija-takki">
                  <TakkiAðgerð1 texti="Búa til fyrirætlun úr ótækum föstum tíma" aðgerð={() => skikkaVeljaLið(true)}/>
                </div>
              )}
            </>
          ) : (
            sjáLeiki ? (
              <LeikirFastur fasturTími={feinginnFastur}/>
            ) : (
              <div className="leikurtímaskrá-gögn">
                <TakkiAðgerð1 texti="Sjá leiki firirætlunar" aðgerð={() => skikkaSjáLeiki(true)}/>
              </div>
            )
          )}
        </>
      )}
      {(!tímiFargaður.current && feinginnFastur && feinginnFastur.upptekni !== 4) && (
        <SpjallÁrnaðarmaður 
          einkitímaskráar={einki ? einki : einkiFastsTíma} 
          breittNíSkilaboð={breittNíSkilaboð} 
          sendaSlóð={`fastur/sendaafiriraetlun/${einki ? einki : einkiFastsTíma}`}
          sækjaSlóð={`fastur/faskilabod/${einki ? einki : einkiFastsTíma}?`}
        />
      )}
      {reinaðEiða && (
        <Smeija lokaSmeiju={skikkaReinaðEiða}>
          <div className="smeija-aðaltakki">
            <VilluHleðurTakki 
              svarMiðlara={svarTakki}
              hleður={hleðurTakki}
              villa={villaTakki}
              textiAðgerðar="Eyða já" 
              aðgerð={eiðaFöstum}
            />
          </div>
          <div className="smeija-aðaltakki">
            <TakkiAðgerð2 
              texti={svarTakki ? "Flotter" : "Nei alls ekki"} 
              aðgerð={() => skikkaReinaðEiða(false)}
            />
          </div>
        </Smeija>
      )}
      {sjáLeikstefni && (
        <Smeija lokaSmeiju={skikkaSjáLeikstefni}>
          <Gutlarasmeija einkigutlara={feinginnFastur.ætlunarstefnir}/>
        </Smeija>
      )}
      {sjáLið && (
        <Smeija lokaSmeiju={skikkaSjáLið}>
          <Liðasmeija 
            lið={{lið: {einki: feinginnFastur.lið, nafn: feinginnFastur.liðsnafn}}}
            erÁrnaðarmaður={true}  
          />
        </Smeija>
      )}
      {veljaLið && (
        <Smeija lokaSmeiju={skikkaVeljaLið}>
          <VeljaLiðFastur 
            einkiFastsTíma={einki ? einki : einkiFastsTíma} 
            skikkaFöstumTíma={skikkaFöstumTíma ? skikkaFöstumTíma : skikkaFeingnumFöstum} 
            skikkaBreittriUpptekni={skikkaBreittriUpptekni}
            lokaSmeiju={skikkaVeljaLið}
            skikkaBreittíFirirætlun={skikkaBreittíFirirætlun}
            skikkaSjáTímaskrár={skikkaSjáTímaskrár}  
          />
        </Smeija>
      )}
    </section>
  )
}

export default ÓtækurFasturSmeija;